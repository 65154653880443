import Script from 'next/script'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { openSans } from '@marketing-sites/tailwind-config/'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, no-multi-assign, global-require, @typescript-eslint/no-unsafe-member-access
  window.jQuery = require('jquery')
}

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Script id="salesforceFormScript">
        {`jQuery(document).ready(function(){

        // Add tags to Iframe URL
      let iframeEl = document.getElementById('3pl-form');
      console.log('js initiated')

      let replaced = false;
      if ( ! iframeEl ) {
       return;
       }
        iframeEl.addEventListener('load', function() {
         let urlParams = new URLSearchParams(window.location.search);

                     let id = urlParams.get('id');
                     let utm_medium = urlParams.get('utm_medium');
                     let utm_content = urlParams.get('utm_content');
                     let utm_campaign = urlParams.get('utm_campaign');
                     let utm_term = urlParams.get('utm_term');
                     let utm_source = urlParams.get('utm_source');
                     let eid = urlParams.get('eid');
                     let cid = urlParams.get('cid');
                     let sfcid = urlParams.get('sfcid');


            if (!replaced ) {
            let iframeEl = jQuery('#3pl-form');
            if ( ! iframeEl ) {
            return;
            }

            let url = iframeEl.attr('src');

             if ( id ) {
               url = url + '&id=' + id;
            }
            if ( cid ) {
               url = url + '&cid=' + cid;
            }
            if ( sfcid ) {
               url = url + '&sfcid=' + sfcid;
            }

            if ( utm_medium ) {
               url = url + '&utm_medium=' + utm_medium;
            }

            if ( utm_content ) {
               url = url + '&utm_content=' + utm_content;
            }

            if ( utm_campaign ) {
               url = url + '&utm_campaign=' + utm_campaign;
            }

            if ( utm_term ) {
               url = url + '&utm_term=' + utm_term;
            }

            if ( utm_source ) {
               url = url + '&utm_source=' + utm_source;
            }

            if ( eid ) {
               url = url + '&eid=' + eid;
            }

            console.log('js completed')

            iframeEl.attr( 'src', url );

            replaced = true;
            }
      });
   });
        `}
      </Script>
      <main className={openSans.variable}>
        <Component {...pageProps} />
      </main>
    </>
  )
}
